<template>
  <div
    class="chat-history-item"
    :class="{ active: isActive }"
    @click="$emit('click')"
  >
    <div class="chat-title">
      {{ chat.title || staticText.newChat }}
    </div>
    <div class="chat-preview">
      {{ chatPreview }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatHistoryItem",
  props: {
    chat: {
      type: Object,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      staticTextDefault: {
        newChat: "New Chat",
        noMessages: "No messages yet"
      }
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    chatPreview () {
      const lastMessage = this.chat.last_message || ""
      return lastMessage.substring(0, 100) || this.staticText.noMessages
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-history-item {
  padding: 12px 20px;
  cursor: pointer;
  border-left: 3px solid transparent;
  transition: all 0.2s ease;

  &:hover {
    background: rgba($orange, 0.05);
  }

  &.active {
    background: rgba($orange, 0.1);
    border-left-color: $orange;
  }

  .chat-title {
    font-size: 14px;
    font-weight: 500;
    color: $slate80;
    margin-bottom: 4px;
  }

  .chat-preview {
    font-size: 13px;
    color: $slate60;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
