<template>
  <div class="chat-input">
    <div class="input-wrapper">
      <textarea
        v-model="message"
        class="form-control"
        :placeholder="inputPlaceholder"
        :disabled="isDisabled"
        @keydown.enter="handleEnter"
        rows="1"
        ref="textarea"
        @input="adjustHeight"
      ></textarea>

      <button
        class="send-button"
        :disabled="!message.trim() || isDisabled"
        @click="sendMessage"
      >
        <img
          src="/img/aiAnswerIcons/magic-star-orange.svg"
          :class="{ disabled: !message.trim() || isDisabled }"
          alt="Send"
        />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatInput",
  props: {
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      message: "",
      staticTextDefault: {
        placeholder: "Ask anything about your conversations..."
      }
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    inputPlaceholder () {
      return this.placeholder || this.staticText.placeholder
    }
  },
  methods: {
    handleEnter (e) {
      if (e.shiftKey) {
        // Allow shift+enter for new line
        return
      }
      e.preventDefault() // Prevent default enter behavior
      this.sendMessage()
    },
    sendMessage () {
      const trimmedMessage = this.message.trim()
      if (trimmedMessage && !this.isDisabled) {
        this.$emit("send", trimmedMessage)
        this.message = ""
        this.$nextTick(() => {
          this.adjustHeight()
        })
      }
    },
    adjustHeight () {
      const textarea = this.$refs.textarea
      if (textarea) {
        textarea.style.height = "auto"
        textarea.style.height = `${textarea.scrollHeight}px`
      }
    }
  },
  mounted () {
    this.adjustHeight()
  }
}
</script>

<style lang="scss" scoped>
.chat-input {
  padding: 16px 24px;

  .input-wrapper {
    position: relative;
    display: flex;
    align-items: flex-end;
    gap: 12px;

    textarea {
      flex: 1;
      resize: none;
      border: 1px solid $gray-200;
      border-radius: 8px;
      padding: 12px 16px;
      font-size: 14px;
      line-height: 1.5;
      min-height: 44px;
      max-height: 120px;
      overflow-y: auto;

      &:focus {
        outline: none;
        border-color: $orange;
      }

      &::placeholder {
        color: $slate40;
      }
    }

    .send-button {
      background: none;
      border: none;
      padding: 8px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      &:disabled {
        cursor: not-allowed;
      }

      img {
        width: 24px;
        height: 24px;
        transition: opacity 0.2s;

        &.disabled {
          opacity: 0.5;
        }
      }
    }
  }
}
</style>
