export default {
  namespaced: true,

  state: {
    activeFilters: {}
  },

  mutations: {
    SET_ACTIVE_FILTERS (state, filters) {
      state.activeFilters = filters
    }
  },

  actions: {
    updateFilters ({ commit }, filters) {
      commit("SET_ACTIVE_FILTERS", filters)
    }
  },

  getters: {
    filtersAsJson: state => state.activeFilters
  }
}
