<template>
  <div class="search-input">
    <input
      type="text"
      :value="value"
      @input="$emit('input', $event.target.value)"
      :placeholder="staticText.searchPlaceholder"
      class="form-control"
    />
  </div>
</template>

<script>
export default {
  name: "ChatHistorySearch",
  data () {
    return {
      staticTextDefault: {
        searchPlaceholder: "Search in history"
      }
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  props: {
    value: {
      type: String,
      default: ""
    }
  }
}
</script>

<style lang="scss" scoped>
.search-input {
  position: relative;
  flex: 1;

  .search-icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    color: $slate60;
  }

  input {
    width: 100%;
    height: 36px;
    padding: 0 12px 0 36px;
    border: 1px solid $gray-200;
    border-radius: 8px;
    font-size: 14px;
    color: $slate80;
    background: white;

    &::placeholder {
      color: $slate40;
    }

    &:focus {
      outline: none;
      border-color: $orange;
    }
  }
}
</style>
