import Vue from "vue"
import Vuex from "vuex"
import users from "@/store/services/users"
import auth from "@/store/modules/auth"
import I18nStore from "@/store/modules/I18nStore"
import calls from "@/store/services/calls"
import playbook from "@/store/services/playbook"
import labels from "@/store/services/labels"
import counterpartStore from "@/store/services/counterpartStore"
import crmStore from "@/store/services/crmStore"
import display from "@/store/modules/display"
import squaapiStore from "@/store/modules/squaapiStore"
import globalError from "@/store/services/globalError"
import dashboardStore from "@/store/services/dashboardStore"
import callSetUpStore from "@/store/services/callSetUpStore"
import toast from "@/store/services/toast"
import playbookConfiguratorStore from "@/store/services/playbookConfiguratorStore"
import teams from "@/store/services/teams"
import callAudioStore from "@/store/services/callAudioStore"
import callSummaryStore from "@/store/services/callSummaryStore"
import callVideoStore from "@/store/services/callVideoStore"
import chat from "@/store/services/chat"
import talkFilters from "./services/talkFilters"

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    users,
    auth,
    I18nStore,
    calls,
    playbook,
    labels,
    counterpartStore,
    crmStore,
    display,
    squaapiStore,
    globalError,
    dashboardStore,
    callSetUpStore,
    toast,
    playbookConfiguratorStore,
    teams,
    callAudioStore,
    callSummaryStore,
    callVideoStore,
    chat,
    talkFilters
  }
})

export default store
