<template>
  <div class="chat-history">
    <div class="header">
      <h2>{{ staticText.title }}</h2>
      <div class="actions">
        <ChatHistorySearch
          v-model="searchQuery"
          @search="handleSearch"
        />
        <button class="btn-new" @click="createNewChat" :title="staticText.newChat">
          <img src="/img/icons/plus-icon.svg" :alt="staticText.newChat" />
        </button>
      </div>
    </div>

    <div class="history-list">
      <process-spinner
        v-if="isInitialLoading"
        :processing-message="staticText.loadingChats"
      />
      <template v-else>
        <div v-for="(group, period) in groupedChats" :key="period" class="time-period">
          <h3>{{ period }}</h3>
          <ChatHistoryItem
            v-for="chat in group"
            :key="chat.id"
            :chat="chat"
            :is-active="chat.id === currentChatId"
            @click="selectChat(chat.id)"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex"
import ChatHistorySearch from "./ChatHistorySearch.vue"
import ChatHistoryItem from "./ChatHistoryItem.vue"
import ProcessSpinner from "@/apps/base/ProcessSpinner.vue"

export default {
  name: "ChatHistory",
  components: {
    ChatHistorySearch,
    ChatHistoryItem,
    ProcessSpinner
  },
  data () {
    return {
      searchQuery: "",
      isInitialLoading: true,
      staticTextDefault: {
        title: "Chat History",
        newChat: "New Chat",
        loadingChats: "Loading chats...",
        today: "Today",
        yesterday: "Yesterday",
        previous7Days: "Previous 7 Days",
        previous30Days: "Previous 30 Days"
      }
    }
  },
  computed: {
    ...mapState("chat", ["currentChatId"]),
    ...mapGetters("chat", ["sortedChats"]),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    filteredChats () {
      if (!this.searchQuery) return this.sortedChats

      const query = this.searchQuery.toLowerCase()
      return this.sortedChats.filter(chat => {
        const title = chat.title || ""
        return title.toLowerCase().includes(query)
      })
    },
    groupedChats () {
      const groups = {
        [this.staticText.today]: [],
        [this.staticText.yesterday]: [],
        [this.staticText.previous7Days]: [],
        [this.staticText.previous30Days]: []
      }

      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const yesterday = new Date(today)
      yesterday.setDate(yesterday.getDate() - 1)
      const last7Days = new Date(today)
      last7Days.setDate(last7Days.getDate() - 7)
      const last30Days = new Date(today)
      last30Days.setDate(last30Days.getDate() - 30)

      this.filteredChats.forEach(chat => {
        if (!chat.created_at) {
          groups[this.staticText.today].push(chat)
          return
        }

        const chatDate = new Date(chat.created_at)
        if (isNaN(chatDate.getTime())) {
          groups[this.staticText.today].push(chat)
          return
        }

        const monthYear = chatDate.toLocaleString("default", { month: "long", year: "numeric" })

        if (chatDate >= today) {
          groups[this.staticText.today].push(chat)
        } else if (chatDate >= yesterday) {
          groups[this.staticText.yesterday].push(chat)
        } else if (chatDate >= last7Days) {
          groups[this.staticText.previous7Days].push(chat)
        } else if (chatDate >= last30Days) {
          groups[this.staticText.previous30Days].push(chat)
        } else {
          if (!groups[monthYear]) {
            groups[monthYear] = []
          }
          groups[monthYear].push(chat)
        }
      })

      // Remove empty groups
      return Object.fromEntries(
        Object.entries(groups).filter(([_, chats]) => chats.length > 0)
      )
    }
  },
  methods: {
    ...mapActions("chat", ["loadChatHistory", "initiateChat", "fetchChats"]),
    handleSearch (query) {
      this.searchQuery = query
    },
    async selectChat (chatId) {
      this.$store.commit("chat/SET_CURRENT_CHAT", chatId)
      await this.loadChatHistory(chatId)
    },
    async createNewChat () {
      this.$store.commit("chat/SET_CURRENT_CHAT", null)
      this.$store.commit("chat/SET_CURRENT_MESSAGES", [])
    }
  },
  async mounted () {
    try {
      await this.fetchChats()
    } finally {
      this.isInitialLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-history {
  background: #faf8f6;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  height: 100%;

  .header {
    padding: 20px;
    border-bottom: 1px solid $gray-200;

    h2 {
      font-size: 20px;
      font-weight: 500;
      color: $slate80;
      margin: 0 0 16px;
    }

    .actions {
      display: flex;
      gap: 8px;

      .btn-new {
        background: white;
        border: 1px solid $gray-200;
        border-radius: 8px;
        width: 36px;
        height: 36px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.2s;

        &:hover {
          border-color: $orange;
          background: rgba($orange, 0.05);
        }

        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .history-list {
    flex: 1;
    overflow-y: auto;
    padding: 0;

    .time-period {
      padding: 16px 0;

      h3 {
        font-size: 12px;
        font-weight: 600;
        color: $slate60;
        text-transform: uppercase;
        padding: 0 20px;
        margin: 0 0 8px;
      }

      & + .time-period {
        border-top: 1px solid rgba(0, 0, 0, 0.05);
      }
    }
  }
}
</style>
