import TalkToYourData from "./TalkToYourData.vue"

export default {
  name: "talk_to_your_data",
  path: "/talk-to-your-data",
  component: TalkToYourData,
  route: {
    path: "/talk-to-your-data",
    component: TalkToYourData,
    name: "talk_to_your_data"
  },
  meta: {
    title: "Talk to Your Data"
  }
}
