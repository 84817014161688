<template>
  <div class="talk-to-your-data">
    <div class="chat-layout">
      <div class="d-flex flex-column">
        <ChatHistory class="chat-history" />
      </div>
      <div class="chat-main">
        <ChatContainer />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import ChatHistory from "./components/ChatHistory/ChatHistory.vue"
import ChatContainer from "./components/ChatContainer.vue"

export default {
  name: "TalkToYourData",
  components: {
    ChatHistory,
    ChatContainer
  },
  methods: {
    ...mapActions("chat", ["fetchChats"])
  }
}
</script>

<style lang="scss" scoped>
.talk-to-your-data {
  height: 100%;
  background: #f0e8de;
  padding: 20px;

  .chat-layout {
    display: flex;
    gap: 20px;
    height: 100%;
    max-width: 1600px;
    margin: 0 auto;

    .chat-history {
      width: 300px;
      flex-shrink: 0;
    }

    .chat-main {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
}
</style>
