<template>
  <div class="chat-container">
    <ChatHeader />
    <div
      class="messages-container"
      ref="messagesContainer"
      @scroll="handleScroll"
    >
      <ChatMessages
        :messages="currentMessages"
        :is-loading="isLoadingMessages"
        :pending-message-id="pendingMessageId"
        ref="chatMessages"
      />
    </div>

    <div class="input-container">
      <ChatInput
        @send="sendMessage"
        :is-disabled="isLoadingMessages"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import ChatHeader from "./Chat/ChatHeader.vue"
import ChatMessages from "./Chat/ChatMessages.vue"
import ChatInput from "./Chat/ChatInput.vue"

export default {
  name: "ChatContainer",
  components: {
    ChatHeader,
    ChatMessages,
    ChatInput
  },
  data () {
    return {
      pendingMessageId: null,
      shouldAutoScroll: true,
      isUserScrolling: false,
      scrollTimeout: null
    }
  },
  computed: {
    ...mapState("chat", ["currentMessages", "isLoadingMessages", "currentChatId"]),
    ...mapGetters("talkFilters", ["filtersAsJson"])
  },
  methods: {
    handleFiltersChanged (filters) {
      this.$store.dispatch("talkFilters/updateFilters", filters)
    },
    handleScroll () {
      if (this.scrollTimeout) {
        clearTimeout(this.scrollTimeout)
      }

      const container = this.$refs.messagesContainer
      if (!container) return

      const isAtBottom = this.isScrolledToBottom(container)
      this.shouldAutoScroll = isAtBottom

      this.isUserScrolling = true

      this.scrollTimeout = setTimeout(() => {
        this.isUserScrolling = false
      }, 150)
    },
    isScrolledToBottom (element) {
      const threshold = 50 // pixels from bottom to consider "at bottom"
      return element.scrollHeight - element.scrollTop - element.clientHeight < threshold
    },
    scrollToBottom (force = false) {
      const container = this.$refs.messagesContainer
      if (!container || (!this.shouldAutoScroll && !force)) return

      if ((this.shouldAutoScroll || force) && !this.isUserScrolling) {
        container.scrollTop = container.scrollHeight
      }
    },
    async sendMessage (content) {
      try {
        const tempId = Date.now().toString()
        this.pendingMessageId = tempId

        // Force scroll to bottom when user sends message
        this.shouldAutoScroll = true
        this.scrollToBottom(true)

        if (!this.currentChatId) {
          // Get filters from store
          const filters = this.filtersAsJson

          await this.$store.dispatch("chat/initiateChat", {
            message: content,
            filters
          })
        } else {
          await this.$store.dispatch("chat/continueChat", {
            message: content,
            chatId: this.currentChatId
          })
        }
      } catch (error) {
        console.error("Failed to send message:", error)
      } finally {
        this.pendingMessageId = null
        this.$nextTick(() => {
          this.scrollToBottom()
        })
      }
    },
    clearFilters () {
      this.$store.dispatch("talkFilters/updateFilters", {})
    }
  },
  watch: {
    currentMessages: {
      deep: true,
      handler () {
        this.$nextTick(() => {
          this.scrollToBottom()
        })
      }
    },
    currentChatId (newId, oldId) {
      if (!newId) {
        this.clearFilters()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #faf8f6;
  border-radius: 12px;
  overflow: hidden;

  .messages-container {
    flex: 1;
    overflow-y: auto;
    padding: 24px;
  }

  .input-container {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }
}
</style>
