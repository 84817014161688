<template>
  <div class="chat-header">
    <advanced-filters
      class="advanced-filters"
      :class="{ 'disabled': !canUseFilters }"
      @filter-calls="filterCalls"
      :disabled="!canUseFilters"
    />
  </div>
</template>

<script>
import { mapState } from "vuex"
import AdvancedFilters from "@/apps/call_history/CallListComponents/AdvancedFilters.vue"

export default {
  name: "ChatHeader",
  components: {
    AdvancedFilters
  },
  computed: {
    ...mapState("talkFilters", ["activeFilters"]),
    ...mapState("chat", ["currentMessages"]),
    canUseFilters () {
      return !this.currentMessages.length
    }
  },
  methods: {
    async filterCalls (filters) {
      if (!this.canUseFilters) return
      await this.$store.dispatch("talkFilters/updateFilters", filters)
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-header {
  padding: 16px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  background: #faf8f6;

  .advanced-filters {
    width: 100%;
    transition: opacity 0.3s ease;

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    }
  }
}
</style>
